import { settingsActionTypes } from './actions';

const initialState = {
  isPwaInstallable: false,
  isPwaInstalled: false,
};

const SettingsReducer = (state = initialState, action) => {
  const { type, data } = action;

  switch (type) {
    case settingsActionTypes.IS_PWA_INSTALLABLE:
      return {
        ...state,
        isPwaInstallable: data,
      };
    case settingsActionTypes.PWA_INSTALLED:
      return {
        ...state,
        isPwaInstalled: data,
      };
    default:
      return state;
  }
};

export default SettingsReducer;
