/* eslint-disable no-shadow */
import React, { Component } from 'react';
import { Layout, Row, Col, Spin, Button } from 'antd';
import { NavLink } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import propTypes from 'prop-types';
import { FloppyDisk } from 'phosphor-react';
// import { FacebookLogo } from 'phosphor-react';

import packageJson from '../../package.json';
import MenuItems from './MenuItems';
import { Div } from './style';
import { getUserData } from '../redux/user/actions';
import { getUserPlatforms } from '../redux/userPlatforms/actions';
import { getOverviewData } from '../redux/overview/actions';
import i18n from '../config/lang/i18n';
import { updateFormatterLocale } from '../hooks/numberFormatters';
// import { FB_PAGE } from '../constants/common';
import Logo from '../components/svg/logo';

const { darkTheme, theme } = require('../config/theme/themeVariables');

const { Footer, Content } = Layout;

const ThemeLayout = (WrappedComponent) => {
  class LayoutComponent extends Component {
    componentDidMount() {
      const { getUserData, getUserPlatforms, getOverviewData } = this.props;
      getUserData();
      getUserPlatforms();
      getOverviewData();
    }

    componentDidUpdate() {
      const { language } = this.props;
      if (language !== null && language !== i18n.language) {
        i18n.changeLanguage(language, () => {
          updateFormatterLocale(language);
        });
      }
    }

    render() {
      const { darkMode, t, isFetched } = this.props;

      if (!isFetched) {
        return (
          <div className="spin">
            <Spin />
          </div>
        );
      }

      return (
        <ThemeProvider theme={darkMode ? darkTheme : theme}>
          <Div>
            {/** TODO: remove this dirty hack to apply the styles from the ButtonStyled component */}
            <Button type="link" style={{ display: 'none' }} />
            <div className="sidebar">
              <NavLink to="/">
                <Logo width={50} height={50} />
              </NavLink>
              <MenuItems />
            </div>
            <div className="main-layout">
              <Content>
                <WrappedComponent {...this.props} />
                <Footer className="footer">
                  <Row>
                    <Col span={24}>
                      <div className="footer-links">
                        <NavLink to="/about-us/motivation">
                          {t('Motivation')}
                        </NavLink>
                        <NavLink to="/about-us/install">
                          <FloppyDisk size={21} style={{ marginRight: 5 }} />
                          {t('Install')}
                        </NavLink>
                        <NavLink to="/about-us/team">{t('Team')}</NavLink>
                        <NavLink to="/about-us/contacts">
                          {t('Contacts')}
                        </NavLink>
                        {/* TODO */}
                        {/* <a href={FB_PAGE} target="_blank">
                          <FacebookLogo size="22" />
                        </a> */}
                        <span>|</span>
                        <span>
                          &copy; {new Date().getFullYear()} P2P Handbook,{' '}
                          <NavLink
                            to="/terms-and-conditions"
                            style={{ display: 'inline' }}
                          >
                            {t('Terms')}
                          </NavLink>
                          {', '}
                          <NavLink
                            to="/privacy-policy"
                            style={{ display: 'inline' }}
                          >
                            {t('Privacy')}
                          </NavLink>{' '}
                          (v{packageJson.version})
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Footer>
              </Content>
            </div>
          </Div>
        </ThemeProvider>
      );
    }
  }

  const mapStateToProps = (state) => ({
    darkMode: state.user.darkMode,
    rtl: state.user.rtlData,
    isFetched: state.user.isFetched,
    language: state.user.language,
  });

  const mapDispatchToProps = {
    getUserData,
    getUserPlatforms,
    getOverviewData,
  };

  LayoutComponent.propTypes = {
    darkMode: propTypes.bool,
    rtl: propTypes.bool,
    t: propTypes.func,
    isFetched: propTypes.bool.isRequired,
    getUserData: propTypes.func,
    getUserPlatforms: propTypes.func,
    getOverviewData: propTypes.func,
    language: propTypes.string,
  };

  return compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
  )(LayoutComponent);
};

export default ThemeLayout;
