import Styled from 'styled-components';

const Div = Styled.div`
    h1, h2, h3, h4, h5, h6 {
        color: ${({ theme }) => theme['heading-color']};
    }
    .sidebar {
        width: 80px;
        box-shadow: 0 0 30px #9299B810;
        position: fixed;
        left: 0;
        background-color: ${({ theme }) => theme['secondary-background']};
        height: 100%;
        z-index: 9;
        > a > svg {
            position: absolute;
            top: 12px;
            left: 50%;
            margin-left: -25px;
        }
        .ant-menu {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            border-right: none;
            background-color: ${({ theme }) => theme['secondary-background']};
            padding: 0 0 calc(env(safe-area-inset-bottom) - 22px) 0;
            .ant-menu-item {
                display: flex;
                align-items: center;
                justify-content: center;
                border-left-width: 2px;
                border-right-width: 2px;
                border-left-style: solid;
                border-right-style: solid;
                border-color: transparent;
                &.ant-menu-item-selected{
                    background-color: transparent;
                    border-right-style: solid;
                    border-color: ${({ theme }) => theme['primary-color']};
                    border-left-color: transparent;
                    svg, i{
                        color: ${({ theme }) => theme['primary-color']};
                    }
                    &:after {
                        opacity: 0;
                    }
                }
                > svg{
                    color: ${({ theme }) => theme['extra-light-color']};
                }
                &:not(:last-child) {
                    margin-bottom: 16px;
                }
            }
        }
        & + .main-layout{
            margin-left: 80px;
        }
    }

    .footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 20px 30px 18px;
        background-color: ${({ theme }) => theme['secondary-background']};
        transition: 0.3s ease;
        box-shadow: 0 -5px 10px rgba(146,153,184, 0.05);
        text-align: right;
        .footer-links {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            color: ${({ theme }) => theme['light-color']};
            gap: 20px;
            a {
                display: flex;
                cursor: pointer;
                color: ${({ theme }) => theme['light-color']};
                &:hover{
                    color: ${({ theme }) => theme['primary-color']};
                }
            }
        }
    }
    @media only screen and (max-width: 1024px){
        .sidebar {
            height: auto;
            width: 100%;
            bottom: 0;
            box-shadow: 0 -5px 10px rgba(146,153,184, 0.05);
            > a > svg {
                display: none;
            }
            .ant-menu {
                width: auto;
                flex-direction: row;
                .ant-menu-item {
                    height: 56px;
                    margin: 0 10px;
                    max-width: 100px;
                    border-right-color: transparent;
                    border-top-width: 2px;
                    border-top-style: solid;
                    border-top-color: transparent;
                    &.ant-menu-item-selected{
                        border-top-style: solid;
                        border-top-width: 2px;
                        border-right-color: transparent;
                    }
                    &:not(:last-child) {
                        margin-bottom: 0px;
                    }
                }
            }
            & + .main-layout{
                margin-left: 0;
            }
        }
        .footer {
            display: none;
        }
    }
`;

export { Div };
