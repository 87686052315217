import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { languages } from '../../constants/lang';
import en from './en.json';
import bg from './bg.json';

const resources = {
  en: {
    app: en,
  },
  bg: {
    app: bg,
  },
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: languages.en,
    supportedLngs: Object.keys(languages),
    detection: {
      caches: null, // the persisted store will take care for it :)
    },
    defaultNS: 'app',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
      wait: true,
    },
    nsSeparator: ':::',
    keySeparator: '::',
  });

export default i18n;
