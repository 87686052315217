import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';
import * as Sentry from '@sentry/react';

import { isDev } from '../constants/common';
import rootReducer from './rootReducers';

const composeEnhancer =
  (isDev &&
    typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;
const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const store = createStore(
  rootReducer,
  composeEnhancer(
    applyMiddleware(thunk.withExtraArgument()),
    sentryReduxEnhancer,
  ),
);

export const persistor = persistStore(store);
export default store;
