import { DataService } from '../../config/dataService/dataService';

export const userPlatformsActionTypes = {
  FETCH: 'FETCH_USER_PLATFORMS_BEGIN',
  SUCCESS: 'FETCH_USER_PLATFORMS_SUCCESS',
  ERROR: 'FETCH_USER_PLATFORMS_ERROR',
};

export const getUserPlatforms = () => (dispatch) => {
  dispatch({
    type: userPlatformsActionTypes.FETCH,
  });

  return DataService.get('accounts')
    .then((data) => {
      dispatch({
        type: userPlatformsActionTypes.SUCCESS,
        data: data.sort(
          (a, b) => a.onboardingCompleted - b.onboardingCompleted,
        ),
      });

      return Promise.resolve();
    })
    .catch((err) => {
      dispatch({
        type: userPlatformsActionTypes.ERROR,
        err: err.message,
      });

      return Promise.reject();
    });
};

export const addAccount = (formValues) => (dispatch) =>
  DataService.post('accounts', formValues)
    .then((data) => {
      dispatch(getUserPlatforms());
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err.message);
    });

export const editAccount = (id, formValues) => (dispatch) =>
  DataService.patch(`accounts/${id}`, formValues)
    .then((data) => {
      dispatch(getUserPlatforms());
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err.message);
    });

export const deleteAccount = (id) => (dispatch) =>
  DataService.delete(`accounts/${id}`)
    .then((data) => {
      dispatch(getUserPlatforms());
      return Promise.resolve(data);
    })
    .catch((err) => {
      return Promise.reject(err.message);
    });

export const getAccountTransactions = (id) => (dispatch) => {
  dispatch({
    type: userPlatformsActionTypes.TRANSACTIONS_BEGIN,
  });

  return DataService.get(`transactions/account/${id}`)
    .then((data) => {
      dispatch({
        type: userPlatformsActionTypes.TRANSACTIONS_SUCCESS,
        data,
      });
    })
    .catch((err) => {
      dispatch({
        type: userPlatformsActionTypes.TRANSACTIONS_ERROR,
        err,
      });
    });
};

// TODO: update the object in the store, instead of fetching all platforms
export const completeAccountOnboarding = (id) => (dispatch) =>
  DataService.patch(`accounts/${id}`, {
    onboardingCompleted: true,
  })
    .then(() => {
      dispatch(getUserPlatforms());
      return Promise.resolve();
    })
    .catch((err) => {
      return Promise.reject(err.message);
    });
