import Styled from 'styled-components';

const AuthLayoutWrapper = Styled.div`
  .auth-side{
    padding: 40px 20px 0;
    text-align: center;
    h1{
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
    }
  }
`;

const AuthWrapper = Styled.div`
  padding: 40px;
  form{
    width: 420px;
    margin: 0 auto;
    h1{
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 0;
      input::placeholder{
        color: ${({ theme }) => theme['extra-light-color']};
      }
    }
    .ant-form-item:last-child{
      margin-top: 20px;
      .ant-form-item-control-input-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    @media only screen and (max-width: 575px){
      width: 100%;
    }
    @media only screen and (max-width: 379px){
      .ant-form-item:last-child{
        .ant-form-item-control-input-content {
          flex-wrap: wrap;
          gap: 15px;
          justify-content: center;
        }
      }
    }
  }
  .auth-notice{
    font-weight: 500;
    margin-bottom: 35px;
    color: ${({ theme }) => theme['gray-color']};
  }
  button{
    font-size: 15px;
    min-width: 180px;
  }
  // Fixes the annoying highlight clash when a password manager suggest a pass
  // TODO: Duplicated, search for: #pass-highlight-clash
  .ant-input-password {
    padding: 0;
    .ant-input {
      padding: 12px 11px;
    }
    .ant-input-suffix {
      padding: 0 12px;
    }
  }
`;

export { AuthLayoutWrapper, AuthWrapper };
