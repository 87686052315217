import moment from 'moment';

import { DataService } from '../../config/dataService/dataService';
import i18n from '../../config/lang/i18n';
import { getOverviewData } from '../overview/actions';
import { TRANSACTIONS } from '../../constants/transactions';

export const transactionsActionTypes = {
  FETCH: 'FETCH_TRANSACTIONS_FETCH',
  SUCCESS: 'FETCH_TRANSACTIONS_SUCCESS',
  ERROR: 'FETCH_TRANSACTIONS_ERROR',
  CLEAR_ALL: 'CLEAR_ALL_TRANSACTIONS',
};

const checkHasAlreadyInterestForThisMonth = (transactions, date, type) => {
  if (type !== TRANSACTIONS.INTEREST) {
    return false;
  }

  const year = moment(date).year();
  if (typeof transactions[year] === 'undefined') {
    return false;
  }

  const month = moment(date).month();
  const sameMonth = transactions[year].find(
    (transaction) =>
      transaction.type === TRANSACTIONS.INTEREST &&
      moment(transaction.date).month() === month,
  );

  return typeof sameMonth !== 'undefined';
};

const checkHasAlreadyInterestForThisMonthAfterEditting = (
  transactions,
  date,
  type,
  transactionId,
) => {
  if (type !== TRANSACTIONS.INTEREST) {
    return false;
  }

  const year = moment(date).year();
  if (typeof transactions[year] === 'undefined') {
    return false;
  }

  const updatingTransaction = transactions[year].find(
    (transaction) => transaction._id === transactionId,
  );

  if (!updatingTransaction) {
    return false;
  }

  const initialMonth = moment(updatingTransaction.date).month();
  const updatingObjectMonth = moment(date).month();

  if (initialMonth === updatingObjectMonth) {
    return false;
  }

  const month = moment(date).month();
  const sameMonth = transactions[year].find(
    (transaction) =>
      transaction.type === TRANSACTIONS.INTEREST &&
      moment(transaction.date).month() === month,
  );

  return typeof sameMonth !== 'undefined';
};

export const getAccountTransactions = (id) => (dispatch) => {
  dispatch({
    type: transactionsActionTypes.FETCH,
  });

  return DataService.get(`transactions/account/${id}`)
    .then((data) => {
      dispatch({
        type: transactionsActionTypes.SUCCESS,
        accountId: id,
        data,
      });
    })
    .catch((err) => {
      dispatch({
        type: transactionsActionTypes.ERROR,
        err,
      });
    });
};

// Add the transaction and re-fetch all transactions again
// TODO: the new transaction can be pushed with a correct position in the array
// of already fetched transactions
export const addAccountTransaction = (id, formFields) => (
  dispatch,
  getState,
) => {
  const { transactions } = getState();
  const { date, type } = formFields;

  const isAlreadyEnteredThisInterest = checkHasAlreadyInterestForThisMonth(
    transactions.data[id],
    date,
    type,
  );

  if (isAlreadyEnteredThisInterest) {
    return Promise.reject(
      i18n.t('The interest for this month has already been entered'),
    );
  }

  return DataService.post(`transactions/account/${id}`, formFields)
    .then(() => {
      dispatch(getAccountTransactions(id));
      return dispatch(getOverviewData());
    })
    .catch((err) => err);
};

// Delete an account's transaction
export const deleteAccountTransaction = (transactionId, accountId) => (
  dispatch,
) =>
  DataService.delete(`transactions/${transactionId}`)
    .then(() => {
      dispatch(getAccountTransactions(accountId));
      return dispatch(getOverviewData());
    })
    .catch((err) => err);

// Update an account's transaction
export const updateAccountTransaction = (
  transactionId,
  accountId,
  formFields,
) => (dispatch, getState) => {
  const { transactions } = getState();
  const { date, type } = formFields;

  const isAlreadyEnteredThisInterest = checkHasAlreadyInterestForThisMonthAfterEditting(
    transactions.data[accountId],
    date,
    type,
    transactionId,
  );

  if (isAlreadyEnteredThisInterest) {
    return Promise.reject(
      i18n.t('The interest for this month has already been entered'),
    );
  }

  return DataService.patch(`transactions/${transactionId}`, formFields)
    .then(() => {
      dispatch(getAccountTransactions(accountId));
      return dispatch(getOverviewData());
    })
    .catch((err) => err);
};
