import { DataService } from '../../config/dataService/dataService';

const actions = {
  LOGIN_BEGIN: 'LOGIN_BEGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERR: 'LOGIN_ERR',

  LOGOUT_BEGIN: 'LOGOUT_BEGIN',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_ERR: 'LOGOUT_ERR',

  REGISTER_BEGIN: 'REGISTER_BEGIN',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_ERR: 'REGISTER_ERR',

  loginBegin: () => ({
    type: actions.LOGIN_BEGIN,
  }),

  loginSuccess: (token) => ({
    type: actions.LOGIN_SUCCESS,
    payload: { token },
  }),

  loginErr: (error) => ({
    type: actions.LOGIN_ERR,
    payload: { error },
  }),

  logoutBegin: () => ({
    type: actions.LOGOUT_BEGIN,
  }),

  logoutSuccess: () => ({
    type: actions.LOGOUT_SUCCESS,
  }),

  logoutErr: (err) => ({
    type: actions.LOGOUT_ERR,
    err,
  }),

  registerBegin: () => ({
    type: actions.REGISTER_BEGIN,
  }),

  registerSuccess: () => ({
    type: actions.REGISTER_SUCCESS,
  }),

  registerErr: (error) => ({
    type: actions.REGISTER_ERR,
    payload: { error },
  }),
};

export const sendPasswordResetCode = (email) =>
  DataService.post('users/password-reset-code', email);

export const resetPassword = (data) =>
  DataService.post('users/password-reset', data);

export default actions;
