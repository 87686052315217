import { combineReducers } from 'redux';
import { persistReducer, purgeStoredState } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from './authentication/reducers';
import authActions from './authentication/actions';
import userReducer from './user/reducers';
import settingsReducer from './settings/reducers';
import userPlatformsReducer from './userPlatforms/reducers';
import platformsReducer from './platforms/reducers';
import overviewReducer from './overview/reducers';
import transactionsReducer from './transactions/reducers';

const commonPersistConfig = {
  storage,
};

const authPersistConfig = {
  key: 'auth',
  whitelist: ['token'],
  ...commonPersistConfig,
};
const userPersistConfig = {
  key: 'user',
  whitelist: ['language', 'privateMode', 'currency', 'darkMode'],
  ...commonPersistConfig,
};

const appReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  user: persistReducer(userPersistConfig, userReducer),
  settings: settingsReducer,
  userPlatforms: userPlatformsReducer,
  platforms: platformsReducer,
  overview: overviewReducer,
  transactions: transactionsReducer,
});

const rootReducers = (state, action) => {
  if (action.type === authActions.LOGOUT_SUCCESS) {
    // Clean auth consent
    purgeStoredState(authPersistConfig);
    // Clean user consent
    purgeStoredState(userPersistConfig);

    // and then -> destroy the state.
    // {@link https://stackoverflow.com/a/35641992/1333836}
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducers;
