import { userPlatformsActionTypes } from './actions';

const initialState = {
  isLoading: false,
  data: [],
  error: null,
};

const UserPlatformsReducer = (state = initialState, action) => {
  const { type, data, err } = action;

  switch (type) {
    case userPlatformsActionTypes.FETCH:
      return {
        ...initialState,
        isLoading: true,
      };
    case userPlatformsActionTypes.SUCCESS:
      return {
        ...state,
        data,
        isLoading: false,
      };
    case userPlatformsActionTypes.ERROR:
      return {
        ...state,
        err,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default UserPlatformsReducer;
