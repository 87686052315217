import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { notification } from 'antd';
import App from './App';
import { isDev } from './constants/common';
import * as serviceWorker from './serviceWorker';
import { Button } from './components/buttons/buttons';
import i18n from './config/lang/i18n';

// Based on the following solution
// {@link: https://github.com/facebook/create-react-app/issues/5316#issuecomment-591075209}
const openNotification = (registration) => {
  const key = `open${Date.now()}`;
  const btn = (
    <Button
      type="primary"
      size="default"
      onClick={async () => {
        if (registration && registration.waiting) {
          await registration.unregister();
          // Makes Workbox call skipWaiting()
          registration.waiting.postMessage({ type: 'SKIP_WAITING' });
          // Once the service worker is unregistered, we can reload the page to let
          // the browser download a fresh copy of our app (invalidating the cache)
          window.location.reload();
        }
      }}
    >
      {i18n.t('Update')}
    </Button>
  );
  notification.open({
    message: i18n.t('New app version available'),
    description: i18n.t('Do you want to get the latest changes?'),
    placement: 'bottomRight',
    duration: null,
    btn,
    key,
  });
};

if (!isDev) {
  Sentry.init({
    dsn:
      'https://1bc86c2747d8470eb031cea3b0d8e11e@o765145.ingest.sentry.io/5794232',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
  });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
navigator.serviceWorker.getRegistration().then((registration) => {
  if (registration.waiting !== null) {
    openNotification(registration);
  }
});
serviceWorker.register();
// serviceWorker.register({
//   onUpdate: (registration) => {
//     // We want to run this code only if we detect a new service worker is
//     // waiting to be activated.
//     // Details about it: https://developers.google.com/web/fundamentals/primers/service-workers/lifecycle
//     openNotification(registration);
//   },
// });
