import { transactionsActionTypes } from './actions';

const initialState = {
  isLoading: false,
  data: {},
  error: null,
};

// const groupTransactionsByYear = (transactions) => {
//   const hash = {};
//   for (let i = 0; i < transactions.length; i++) {
//     const year = transactions[i].date.slice(0, 4);
//     if (!hash[year]) hash[year] = [];
//     hash[year].push(transactions[i]);
//   }
//   return hash;
// }

const UserPlatformsReducer = (state = initialState, action) => {
  const { type, data, err } = action;

  switch (type) {
    case transactionsActionTypes.FETCH:
      return {
        ...initialState,
        isLoading: true,
      };

    case transactionsActionTypes.SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: {
          ...state.data,
          // [action.accountId]: groupTransactionsByYear(data),
          [action.accountId]: data,
        },
      };

    case transactionsActionTypes.ERROR:
      return {
        ...state,
        err,
        isLoading: false,
      };

    case transactionsActionTypes.CLEAR_ALL:
      return initialState;

    default:
      return state;
  }
};

export default UserPlatformsReducer;
