import { overviewActionTypes } from './actions';

const initialState = {
  isLoading: true,
  error: null,
  totalHoldings: null,
  totalGain: null,
  annualGrowthRate: null,
  annualGrowthRateLastUpdated: null,
  totalHoldingsByAccount: [],
};

const OverviewReducer = (state = initialState, action) => {
  const { type, data, err } = action;

  switch (type) {
    case overviewActionTypes.FETCH:
      return {
        ...initialState,
        isLoading: true,
      };
    case overviewActionTypes.SUCCESS:
      return {
        ...state,
        ...data,
        isLoading: false,
      };
    case overviewActionTypes.ERROR:
      return {
        ...state,
        err,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default OverviewReducer;
