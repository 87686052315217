import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, Redirect } from 'react-router-dom';
import withAdminLayout from '../../layout/withAdminLayout';

const Overview = lazy(() => import('../../container/overview'));
const Accounts = lazy(() => import('../../routes/admin/accounts'));
const Charts = lazy(() => import('../../container/charts'));
const Settings = lazy(() => import('../../container/settings'));
const AboutUs = lazy(() => import('../../container/about-us'));
const InstallApp = lazy(() => import('../../container/install-app'));
const Welcome = lazy(() => import('../../container/welcome'));
const FirstSteps = lazy(() => import('../../container/first-steps'));
const PrivacyPolicy = lazy(() => import('../../container/privacy-policy'));
const TermsAndConditions = lazy(() =>
  import('../../container/terms-and-conditions'),
);

const Admin = () => (
  <Switch>
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Route exact path="/" component={Overview} />
      <Route path="/overview" component={Overview} />
      <Route path="/accounts" component={Accounts} />
      <Route path="/charts" component={Charts} />
      <Route path="/settings" component={Settings} />
      <Route path="/about-us" component={AboutUs} />
      <Route path="/install" component={InstallApp} />
      <Route path="/welcome" component={Welcome} />
      <Route path="/first-steps" component={FirstSteps} />
      <Route path="/privacy-policy" component={PrivacyPolicy} />
      <Route path="/terms-and-conditions" component={TermsAndConditions} />

      {/* Redirect the auth routes to the home page */}
      <Route path="/login" exact render={() => <Redirect to="/" />} />
      <Route path="/register" exact render={() => <Redirect to="/" />} />
      <Route path="/activate" exact render={() => <Redirect to="/" />} />
      <Route path="/new-password" exact render={() => <Redirect to="/" />} />
      <Route
        path="/forgotten-password"
        exact
        render={() => <Redirect to="/" />}
      />
    </Suspense>
  </Switch>
);

export default withAdminLayout(Admin);
