import actions from './actions';

const {
  LOGIN_BEGIN,
  LOGIN_SUCCESS,
  LOGIN_ERR,
  LOGOUT_BEGIN,
  LOGOUT_SUCCESS,
  LOGOUT_ERR,
  REGISTER_BEGIN,
  REGISTER_SUCCESS,
  ACTIVATION_BEGIN,
  ACTIVATION_SUCCESS,
  REGISTER_ERR,
} = actions;

const initState = {
  token: null,
  loading: false,
  error: null,
  register: {
    isRegistering: false,
    isActivating: false,
    error: null,
  },
};

const AuthReducer = (state = initState, action) => {
  const { type, payload, err } = action;
  switch (type) {
    case LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        token: payload.token,
      };
    case LOGIN_ERR:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case LOGOUT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...initState,
        token: null,
      };
    case LOGOUT_ERR:
      return {
        ...state,
        error: err,
        loading: false,
      };
    case REGISTER_BEGIN:
      return {
        ...state,
        register: {
          isRegistering: true,
          isActivating: false,
          error: null,
        },
      };
    case REGISTER_SUCCESS:
      return {
        ...state,
        register: {
          isRegistering: false,
          isActivating: false,
          error: null,
        },
      };
    case ACTIVATION_BEGIN:
      return {
        ...state,
        register: {
          isRegistering: false,
          isActivating: true,
          error: null,
        },
      };
    case ACTIVATION_SUCCESS:
      return {
        ...state,
        register: {
          isRegistering: false,
          isActivating: false,
          error: null,
        },
      };
    case REGISTER_ERR:
      return {
        ...state,
        register: {
          isRegistering: false,
          isActivating: false,
          error: payload.error,
        },
      };
    default:
      return state;
  }
};
export default AuthReducer;
