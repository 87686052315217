import Dinero from 'dinero.js';
import { useSelector } from 'react-redux';

export const updateFormatterLocale = (lang) => {
  const locales = {
    en: 'en-EN',
    bg: 'bg-BG',
  };

  Dinero.globalLocale = locales[lang];
};

export const useCurrencyFormatter = () => {
  let currency = useSelector((state) => state.user.currency);
  const isPrivateMode = useSelector((state) => state.user.privateMode);
  // TODO: format the numbers based the user's language

  return (value, decimals = 2, curr = null, ignorePrivateMode = false) => {
    if (isPrivateMode && !ignorePrivateMode) {
      return '•••••';
    }

    if (curr) {
      currency = curr;
    }

    if (typeof value !== 'number') {
      return (
        Dinero({ amount: 0, currency })
          //   .setLocale('bg-BG')
          .toFormat()
      );
    }

    // Set custom decimals: it's a combination of two approaches:
    // 1. use an alternative way of `toFormat()` recommended from the documentation
    // https://dinerojs.com/module-dinero#~toFormat
    // 2. Managing the decimals into the `toLocaleString`
    // https://stackoverflow.com/a/51779528/2765346
    const locale = Dinero().getLocale();
    return Dinero({ amount: value })
      .toRoundedUnit(decimals)
      .toLocaleString(locale, {
        style: 'currency',
        currency,
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      });

    // return (
    //   Dinero({ amount: value, currency, precision: 0 })
    //     // .setLocale('bg-BG')
    //     .toFormat()
    // );
  };
};
