import { platformsActionTypes } from './actions';

const initialState = {
  isLoading: true,
  data: {},
  error: null,
};

const PlatformsReducer = (state = initialState, action) => {
  const { type, data, err } = action;

  switch (type) {
    case platformsActionTypes.FETCH:
      return {
        ...initialState,
        isLoading: true,
      };
    case platformsActionTypes.SUCCESS:
      return {
        ...state,
        data,
        isLoading: false,
      };
    case platformsActionTypes.ERROR:
      return {
        ...state,
        err,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default PlatformsReducer;
