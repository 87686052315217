import React, { lazy, Suspense } from 'react';
import { Spin } from 'antd';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthLayout from '../container/profile/authentication/Index';

const Login = lazy(() =>
  import('../container/profile/authentication/overview/Login'),
);
const Register = lazy(() =>
  import('../container/profile/authentication/overview/Register'),
);
const ForgottenPassword = lazy(() =>
  import('../container/profile/authentication/overview/ForgottenPassword'),
);
const NewPassword = lazy(() =>
  import('../container/profile/authentication/overview/NewPassword'),
);
const Activate = lazy(() =>
  import('../container/profile/authentication/overview/Activate'),
);
const PrivacyPolicy = lazy(() => import('../container/privacy-policy'));
const TermsAndConditions = lazy(() =>
  import('../container/terms-and-conditions'),
);

const AuthenticationRoutes = () => {
  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin />
        </div>
      }
    >
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/forgotten-password" component={ForgottenPassword} />
        <Route exact path="/new-password" component={NewPassword} />
        <Route exact path="/activate" component={Activate} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route
          exact
          path="/terms-and-conditions"
          component={TermsAndConditions}
        />
        <Redirect to="/" />
      </Switch>
    </Suspense>
  );
};

export default AuthLayout(AuthenticationRoutes);
