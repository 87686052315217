import { DataService } from '../../config/dataService/dataService';

export const overviewActionTypes = {
  FETCH: 'FETCH_OVERVIEW_BEGIN',
  SUCCESS: 'FETCH_OVERVIEW_SUCCESS',
  ERROR: 'FETCH_OVERVIEW_ERROR',
};

export const getOverviewData = () => (dispatch) => {
  dispatch({
    type: overviewActionTypes.FETCH,
  });

  return DataService.get('stats')
    .then((data) => {
      dispatch({
        type: overviewActionTypes.SUCCESS,
        data,
      });

      return Promise.resolve();
    })
    .catch((err) => {
      dispatch({
        type: overviewActionTypes.ERROR,
        err: err.message,
      });

      return Promise.reject();
    });
};
