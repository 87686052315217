import { userActionTypes } from './actions';
import staticData from '../../config/config';
import { THEMES } from '../../constants/common';
import { chartPalette } from '../../constants/chart';

const initialState = {
  name: null,
  email: null,
  isFetched: false,
  privateMode: true,
  currency: 'EUR',
  darkMode: staticData.darkMode,
  chartsPalette: chartPalette.INTERPOLATE_PLASMA,
  rtlData: staticData.rtl,
  language: null,
  avatarFileName: null,
  error: null,
};

const UserReducer = (state = initialState, action) => {
  const { type, data, err } = action;

  switch (type) {
    case userActionTypes.FETCH_USER_SUCCESS:
      return {
        ...state,
        isFetched: true,
        name: data.name,
        email: data.email,
        language: data.lang.toLowerCase(),
        privateMode: data.enablePrivateMode,
        currency: data.baseCurrency,
        darkMode: data.theme === THEMES.DARK,
        avatarFileName: data.avatarFileName,
        chartsPalette: data.chartsPalette || chartPalette.INTERPOLATE_PLASMA,
        totpActivated: data.totpActivated,
      };
    case userActionTypes.FETCH_USER_ERROR:
      return {
        ...state,
        error: err,
      };
    case userActionTypes.CHANGE_PERSONAL_NAME:
      return {
        ...state,
        name: data,
      };
    case userActionTypes.CHANGE_LANGUAGE:
      return {
        ...state,
        language: data,
      };
    case userActionTypes.CHANGE_THEME:
      return {
        ...state,
        darkMode: data,
      };
    case userActionTypes.CHANGE_PRIVATE_MODE:
      return {
        ...state,
        privateMode: data,
      };
    case userActionTypes.CHANGE_CHART_PALETTE:
      return {
        ...state,
        chartsPalette: data,
      };
    case userActionTypes.CHANGE_CURRENCY:
      return {
        ...state,
        currency: data,
      };
    case userActionTypes.CHANGE_AVATAR:
      return {
        ...state,
        avatarFileName: data,
      };
    default:
      return state;
  }
};

export default UserReducer;
