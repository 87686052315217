import React from 'react';
import { Menu } from 'antd';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import { HouseLine, ChartBar, Wallet, Info } from 'phosphor-react';

const MenuItems = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const location = useLocation();

  const { pathname } = location;
  const locationKey = pathname.split('/')[1];
  const isMainKeySelected = locationKey === '';
  const activeKey = isMainKeySelected ? 'dashboard' : locationKey;

  return (
    <Menu mode="inline" selectedKeys={[activeKey]} inlineIndent={16}>
      <Menu.Item
        key="dashboard"
        icon={
          <HouseLine
            size={30}
            weight={activeKey === 'dashboard' ? 'duotone' : 'regular'}
          />
        }
        onClick={() => history.push(path)}
      />
      <Menu.Item
        key="accounts"
        icon={
          <Wallet
            size={30}
            weight={activeKey === 'accounts' ? 'duotone' : 'regular'}
          />
        }
        onClick={() => history.push(`${path}accounts`)}
      />
      <Menu.Item
        key="charts"
        icon={
          <ChartBar
            weight={activeKey === 'charts' ? 'duotone' : 'regular'}
            size={30}
          />
        }
        weight={activeKey === 'charts' ? 'duotone' : 'regular'}
        onClick={() => history.push(`${path}charts`)}
      />
      <Menu.Item
        key="about-us"
        icon={
          <Info
            size={30}
            weight={activeKey === 'about-us' ? 'duotone' : 'regular'}
          />
        }
        onClick={() => history.push(`${path}about-us`)}
      />
    </Menu>
  );
};

export default MenuItems;
