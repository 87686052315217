import { DataService } from '../../config/dataService/dataService';

export const platformsActionTypes = {
  FETCH: 'FETCH_PLATFORMS_BEGIN',
  SUCCESS: 'FETCH_PLATFORMS_SUCCESS',
  ERROR: 'FETCH_PLATFORMS_ERROR',
};

export const getAllPlatforms = () => (dispatch) => {
  dispatch({
    type: platformsActionTypes.FETCH,
  });

  return DataService.get('platforms?keyBy=_id')
    .then((data) => {
      dispatch({
        type: platformsActionTypes.SUCCESS,
        data,
      });

      return Promise.resolve();
    })
    .catch((err) => {
      dispatch({
        type: platformsActionTypes.ERROR,
        err: err.message,
      });

      return Promise.reject();
    });
};
