export const chartPalette = {
  INTERPOLATE_RAINBOW: 'interpolateRainbow',
  INTERPOLATE_SINEBOW: 'interpolateSinebow',
  INTERPOLATE_COOL: 'interpolateCool',
  INTERPOLATE_WARM: 'interpolateWarm',
  INTERPOLATE_PLASMA: 'interpolatePlasma',
  INTERPOLATE_INFERNO: 'interpolateInferno',
  INTERPOLATE_VIRIDIS: 'interpolateViridis',
  INTERPOLATE_TURBO: 'interpolateTurbo',
  INTERPOLATE_SPECTRAL: 'interpolateSpectral',
  INTERPOLATE_RD_YL_GN: 'interpolateRdYlGn',
};
