import React, { useEffect, useState, useCallback } from 'react';
import { hot } from 'react-hot-loader/root';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { ConfigProvider } from 'antd';

import store, { persistor } from './redux/store';
import Admin from './routes/admin';
import Auth from './routes/auth';
import './static/css/style.css';
import config from './config/config';
import './config/lang/i18n';
import ProtectedRoute from './components/utilities/protectedRoute';
import { settingsActionTypes } from './redux/settings/actions';

const { theme, darkTheme } = config;

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ activeTheme }) => activeTheme['primary-background']};
  }
  .ant-popover-arrow {
    border-top-color: ${({ activeTheme }) =>
      activeTheme['popover-background']} !important;
    border-left-color: ${({ activeTheme }) =>
      activeTheme['popover-background']} !important;
  }
  .ant-popover-inner {
    background-color: ${({ activeTheme }) =>
      activeTheme['popover-background']} !important;
  }
  .ant-select-dropdown {
    background-color: ${({ activeTheme }) =>
      activeTheme['popover-background']} !important;
  }
  .ant-select-item-option-content {
    color: ${({ activeTheme }) => activeTheme['text-color']} !important;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: ${({ activeTheme }) =>
      activeTheme['text-color']}15 !important;
  }
`;

const ProviderConfig = () => {
  const dispatch = useDispatch();
  // {@link https://stackoverflow.com/a/58624276/1333836}
  const stableDispatch = useCallback(dispatch, []);

  const { isLoggedIn, darkMode } = useSelector((state) => ({
    darkMode: state.user.darkMode,
    isLoggedIn: !!state.auth.token,
  }));

  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setPath(window.location.pathname);
    }
    // eslint-disable-next-line no-return-assign
    return () => (unmounted = true);
  }, [setPath]);

  // Required in order to trigger the custom in-app install experience later
  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      window.deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      // setInstallable(true);
      stableDispatch({
        type: settingsActionTypes.IS_PWA_INSTALLABLE,
        data: true,
      });
    });

    // Detect if user is browsing the app in PWA mode
    // {@link https://stackoverflow.com/a/51735941/1333836}
    if (window.matchMedia('(display-mode: standalone)').matches) {
      // do things here
      // set a variable to be used when calling something
      // e.g. call Google Analytics to track standalone use
      stableDispatch({ type: settingsActionTypes.PWA_INSTALLED, data: true });
    }
  }, [stableDispatch]);

  return (
    <ConfigProvider>
      <GlobalStyle activeTheme={darkMode ? darkTheme : theme} />
      <ThemeProvider theme={{ ...theme, darkMode }}>
        <Router basename={process.env.PUBLIC_URL}>
          {!isLoggedIn ? (
            <Route path="/" component={Auth} />
          ) : (
            <ProtectedRoute path="/" component={Admin} />
          )}
          {isLoggedIn &&
            (path === process.env.PUBLIC_URL ||
              path === `${process.env.PUBLIC_URL}/`) && <Redirect to="/" />}
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
};

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ProviderConfig />
      </PersistGate>
    </Provider>
  );
}

export default hot(App);
