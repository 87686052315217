import React from 'react';
import { useTranslation } from 'react-i18next';
import { AuthLayoutWrapper } from './overview/style';
import Heading from '../../../components/heading/heading';
import Logo from '../../../components/svg/logo';

const AuthLayout = (WrapperContent) => () => {
  const { t } = useTranslation();

  return (
    <AuthLayoutWrapper>
      <div className="auth-side">
        <Logo isOnlyLogo={false} width={200} height={48} />
        <Heading as="h1">
          {t('Keep track of your P2P investment profits.')}
        </Heading>
      </div>
      <WrapperContent />
    </AuthLayoutWrapper>
  );
};

export default AuthLayout;
