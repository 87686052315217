import { DataService } from '../../config/dataService/dataService';
import { THEMES } from '../../constants/common';
import { getOverviewData } from '../overview/actions';
import { transactionsActionTypes } from '../transactions/actions';

export const userActionTypes = {
  FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
  FETCH_USER_ERROR: 'FETCH_USER_ERROR',
  CHANGE_PERSONAL_NAME: 'CHANGE_PERSONAL_NAME',
  CHANGE_PRIVATE_MODE: 'CHANGE_PRIVATE_MODE',
  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
  CHANGE_THEME: 'CHANGE_THEME',
  CHANGE_CHART_PALETTE: 'CHANGE_CHART_PALETTE',
  CHANGE_AVATAR: 'CHANGE_AVATAR',
  CHANGE_CURRENCY: 'CHANGE_CURRENCY',
};

// Get the user's data. Typically used on initial load
export const getUserData = () => (dispatch) =>
  DataService.get('users/me')
    .then((data) => {
      dispatch({
        type: userActionTypes.FETCH_USER_SUCCESS,
        data,
      });
    })
    .catch((err) => {
      dispatch({
        type: userActionTypes.FETCH_USER_ERROR,
        err: err.message,
      });
    });

// Update the Private mode. First, store the property in the redux store,
// so we can see the visual change, then - save the preference in the DB.
export const changePrivateMode = (isPrivateMode) => (dispatch) => {
  dispatch({
    type: userActionTypes.CHANGE_PRIVATE_MODE,
    data: isPrivateMode,
  });

  DataService.put('users/me', { enablePrivateMode: isPrivateMode }).catch(
    () => {
      // Close your eyes... nothing happened
    },
  );
};

// Update the language. First, store the property in the redux store,
// so we can see the visual change, then - save the preference in the DB.
export const changeLanguage = (lang) => (dispatch) => {
  dispatch({
    type: userActionTypes.CHANGE_LANGUAGE,
    data: lang,
  });

  DataService.put('users/me', { lang: lang.toUpperCase() }).catch(() => {
    // Close your eyes... nothing happened
  });
};

// Update the theme mode. First, store the property in the redux store,
// so we can see the visual change, then - save the preference in the DB.
export const changeThemeMode = (isDarkMode) => (dispatch) => {
  dispatch({
    type: userActionTypes.CHANGE_THEME,
    data: isDarkMode,
  });

  const newTheme = isDarkMode ? THEMES.DARK : THEMES.LIGHT;
  DataService.put('users/me', { theme: newTheme }).catch(() => {
    // Close your eyes... nothing happened
  });
};

// Update the user's personal name. First, store the property in the redux store,
// so we can see the visual change, then - save the preference in the DB.
export const changePersonalName = (name) => async (dispatch) => {
  dispatch({
    type: userActionTypes.CHANGE_PERSONAL_NAME,
    data: name,
  });

  DataService.put('users/me', { name }).catch(() => {
    // Close your eyes... nothing happened
  });
};

// Upload a new avatar
export const changeAvatar = (fmData, onSuccess, onError) => async (dispatch) =>
  DataService.upload('users/me/avatar', fmData)
    .then((res) => {
      dispatch({
        type: userActionTypes.CHANGE_AVATAR,
        data: res.avatarFileName,
      });

      onSuccess();
    })
    .catch((err) => {
      onError(err);
    });

// Update the chart palette. First, store the property in the redux store,
// so we can see the visual change, then - save the preference in the DB.
export const changeChartPalette = (palette) => (dispatch) => {
  dispatch({
    type: userActionTypes.CHANGE_CHART_PALETTE,
    data: palette,
  });

  DataService.put('users/me', { chartsPalette: palette }).catch(() => {
    // Close your eyes... nothing happened
  });
};

// Update the chart palette. First, store the property in the redux store,
// so we can see the visual change, then - save the preference in the DB.
export const changeCurrency = (currency) => (dispatch) => {
  dispatch({
    type: userActionTypes.CHANGE_CURRENCY,
    data: currency,
  });

  DataService.put('users/me', { baseCurrency: currency })
    .then(() => {
      // Re-fetch the overview again with the new currency values
      dispatch(getOverviewData());

      // Clear all transactions... they will be re-fetched with the new currency
      dispatch({
        type: transactionsActionTypes.CLEAR_ALL,
      });
    })
    .catch(() => {
      // Close your eyes... nothing happened
    });
};

// Simply change the password
export const changePassword = (oldPassword, newPassword) => (dispatch) =>
  DataService.patch('users/me/change-password', {
    password: oldPassword,
    newPassword,
  });

export const request2FA = () => (dispatch) =>
  DataService.get('users/2fa/totp-request-secret');

export const activate2FA = (token) => (dispatch) =>
  DataService.post('users/2fa/totp-activate', { token });

export const deactivate2FA = () => (dispatch) =>
  DataService.delete('users/2fa/totp-activate');
