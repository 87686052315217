export const isDev =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const THEMES = {
  DARK: 'DARK',
  LIGHT: 'LIGHT',
};

export const APP_NAME = 'P2P Handbook';
export const FB_PAGE = 'https://www.facebook.com/p2phandbookbg';
export const CONTACT_EMAIL = 'hi@p2phandbook.com';
